import slugify from './slugify';
import {ProductData} from './components/Product';

export interface CategoryNode {
  id: string;
  data: {
    name: string;
    parent?: {
      id: string;
    }[];
  };
}

export interface Category {
  id: string;
  name: string;
  subcategories: Category[];
  path: string;
  expanded: boolean;
}

function sortAndAddPath(categories: Category[], path: string, currentPath?: string) {
  categories.sort((a, b) => a.name.localeCompare(b.name));
  let anyExpanded = false;
  for (let category of categories) {
    const slug = slugify(category.name);
    category.path = `${path}${slug}/`;
    if (category.path === currentPath) {
      category.expanded = true;
      anyExpanded = true;
    }
    const anyChildExpanded = sortAndAddPath(category.subcategories, category.path, currentPath);
    if (anyChildExpanded) {
      category.expanded = true;
      anyExpanded = true;
    }
  }
  return anyExpanded;
}

export function makeTree(nodes: CategoryNode[], currentPath?: string) {
  const categories: {[key: string]: Category} = {};
  const roots: {[key: string]: Category} = {};
  for (const node of nodes) {
    categories[node.id] = {id: node.id, name: node.data.name, subcategories: [], path: '/', expanded: false};
    if (!node.data.parent) {
      roots[node.id] = categories[node.id];
    }
  }
  for (const {
    id,
    data: {parent: parents},
  } of nodes) {
    if (parents) {
      const [{id: parentID}] = parents;
      const parent = categories[parentID];
      if (!parent) {
        console.log(`Parent with ID ${parentID} not found`);
        continue;
      }
      parent.subcategories.push(categories[id]);
    }
  }
  const result = Object.values(roots);
  sortAndAddPath(result, '/', currentPath);
  return result;
}

export function filterProducts(
  products: {id: string; data: ProductData}[],
  categoryID: string,
  categoryNodes: CategoryNode[],
) {
  const includedCategories = new Set<string>();
  const categories: {[key: string]: CategoryNode} = {};
  for (const node of categoryNodes) {
    categories[node.id] = node;
  }
  for (const node of categoryNodes) {
    let parent = node;
    while (parent) {
      if (parent.id === categoryID) includedCategories.add(node.id);
      parent = parent.data.parent && categories[parent.data.parent[0].id];
    }
  }

  return products.filter(product => includedCategories.has(product.data.category[0].id));
}
