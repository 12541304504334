import {graphql} from 'gatsby';
import {css, cx} from 'linaria';
import * as React from 'react';
import Page, {PageProps} from '../components/Page';
import Sidebar, {SidebarData} from '../components/Sidebar';

export default ({data: sidebarData, location, ...props}: {data: AboutData} & PageProps) => (
  <Page title="About Just Pick This" location={location} {...props}>
    <div className="centered">
      <Sidebar data={sidebarData} pathname={location.pathname} />
      <div
        className={cx(
          'centered',
          css`
            max-width: 460px;
            text-align: left;
            @media (min-width: 480px) {
              padding-left: 5vh;
            }
          `,
        )}>
        <h2 className="sc l b mbm mtl">About Just Pick This</h2>
        <p className="mbl">We pick the best products so you don’t have to.</p>

        <h3 className="b mbs">Why trust us?</h3>
        <p className="mbl">
          We spend countless hours researching and testing products, and only feature the ones that are clear winners in
          their category. Give them a try, we think you’ll like them.
        </p>

        <h3 className="b mbs">Have feedback?</h3>
        <p className="mbl">
          Did we miss a great product? Disagree with one of our choices?{' '}
          <a href="mailto:tips@justpickthis.com" className="b">
            Let us know
          </a>
          .
        </p>

        <h3 className="b mbs">Are we biased?</h3>
        <p className="mbl">
          We only feature the best products regardless of who makes them or who sells them. We do not take any money
          from the companies we feature. That said, we do take a small cut if you end up buying a product on one of the
          stores that offers commissions.
        </p>

        <h3 className="b mbs">Why?</h3>
        <p className="mbl">
          We love great products and believe that the best products should be shared and celebrated. That’s also why the
          site looks like it does: to showcase each product beautifully. Unlike other review sites, we like to let the
          products speak for themselves.
        </p>
      </div>
    </div>
  </Page>
);

type AboutData = SidebarData;

export const query = graphql`
  {
    ...SidebarFragment
  }
`;
