import * as React from 'react';
import {css} from 'linaria';
import {Helmet} from 'react-helmet';

css`
  :global() {
    h1,
    h2,
    h3,
    button,
    p,
    html,
    body,
    ul,
    li {
      font: inherit;
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    html,
    body {
      height: 100%;
      min-height: 100%;
      font: 12px/20px 'Karla', -apple-system, BlinkMacSystemFont, sans-serif;
      color: #333;
      box-sizing: border-box;
    }
    body {
      text-align: center;
      padding: 40px;
      margin-left: auto;
      margin-right: auto;
    }
    a {
      text-decoration: none;
      color: #333;
      display: inline-block;
      transition: 0.1s transform;
    }
    a:hover {
      transform: scale(1.05);
    }
    a:active {
      transform: scale(0.9);
    }
    .button {
      border: 1px solid #333;
      color: #333;
      background: #fff;
      padding: 6px 12px;
      cursor: pointer;
    }
    .button:hover {
      background: #000;
      color: #fff;
      transform: scale(1.05);
    }
    .button:active {
      transform: scale(0.9);
    }
    .input {
      font: inherit;
      padding: 4px 0px 4px 6px;
      border: 1px solid #ccc;
      margin: -1px 0 0 -1px;
      min-width: 60px;
      box-sizing: border-box;
    }
    .textarea {
      height: 100px;
    }
    .row {
      display: flex;
      align-items: flex-start;
    }
    .col {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
    .f1 {
      flex: 1;
    }
    .f2 {
      flex: 2;
    }
    .f4 {
      flex: 4;
    }
    .label {
      text-align: left;
      font-weight: bold;
      min-width: 60px;
      box-sizing: border-box;
      margin: -1px 0 0 -1px;
      padding: 4px 0px 4px 6px;
    }
    .centered {
      margin-left: auto;
      margin-right: auto;
    }
    .fixed {
      position: fixed;
    }
    .left {
      text-align: left;
    }
    .block {
      display: block;
    }
    .ib {
      display: inline-block;
      vertical-align: middle;
    }
    .sc {
      font-size: 95%;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .b {
      font-weight: 700;
    }
    .m {
      font-size: 12px;
    }
    .l {
      font-size: 14px;
    }
    .mbxs {
      margin-bottom: 4px;
    }
    .mbs {
      margin-bottom: 8px;
    }
    .mbm {
      margin-bottom: 16px;
    }
    .mbl {
      margin-bottom: 32px;
    }
    .mtl {
      margin-top: 32px;
    }
    .mlm {
      margin-left: 16px;
    }
    .off {
      display: none;
    }
    .toggle {
      position: relative;
      top: -1px;
      cursor: default;
      display: inline-block;
      width: 12px;
    }
    @media (min-width: 480px) {
      .d-ib {
        display: inline-block;
        vertical-align: middle;
      }
      .d-w40 {
        width: 40%;
      }
      .d-w20 {
        width: 20%;
      }
      .d-mlm {
        margin-left: 16px;
      }
      .d-mh500 {
        min-height: 500px;
      }
    }
    @media (max-width: 479px) {
      .m-off {
        display: none;
      }
      .m-mh300 {
        min-height: 300px;
      }
      .m-w100 {
        width: 100%;
      }
    }
  }
`;

export interface PageProps {
  location: {pathname: string; query: string; hash: string};
}

export default function Page({
  title = 'Just Pick This – The best products for you and your home',
  children,
}: {title?: string; children?: React.ReactNode} & PageProps) {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="//fonts.googleapis.com/css?family=Karla:400,700" />
        <title>{title}</title>
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
      </Helmet>
      {children}
      <script
        dangerouslySetInnerHTML={{
          __html: `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
document,'script','https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1681069845483288');
fbq('track', "PageView");`,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
ga('create', 'UA-1809956-10', 'auto');
ga('send', 'pageview');
var buttons = document.getElementsByClassName('buy');
for (var i = 0; i < buttons.length; i++) {
  buttons[i].addEventListener('click', function() {
    fbq('track', 'Lead');
  });
}`,
        }}
      />
    </>
  );
}
