import {graphql, Link} from 'gatsby';
import {css, cx} from 'linaria';
import * as React from 'react';
import {CategoryNode, makeTree} from '../categoryTree';

function CategoryLink({category: {name, subcategories, path, expanded: defaultExpanded}}) {
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const onExpandClick = React.useCallback(() => {
    setExpanded(exp => !exp);
  }, []);

  return (
    <li>
      <span
        onClick={onExpandClick}
        className={cx(
          'toggle',
          subcategories.length === 0 &&
            css`
              visibility: hidden;
            `,
        )}>
        {expanded ? '–' : '+'}
      </span>
      <Link to={path} activeClassName="b">
        {name}
      </Link>
      {expanded && (
        <ul
          className={css`
            padding-left: 12px;
          `}>
          {subcategories.map(category => (
            <CategoryLink key={category.id} category={category} />
          ))}
        </ul>
      )}
    </li>
  );
}

function Sidebar({
  data: {
    categories: {nodes},
  },
  pathname,
}: {
  data: SidebarData;
  pathname: string;
}) {
  const categories = React.useMemo(() => makeTree(nodes, pathname), [nodes]);
  return (
    <nav
      className={cx(
        'fixed left',
        css`
          top: 20px;
          left: 20px;
        `,
      )}>
      <h1>
        <Link to="/" className="sc b mbm block">
          <span className="m">🍒</span>
          Just Pick This
        </Link>
      </h1>
      <Link to="/about" className="sc mbl block m-off" activeClassName="b">
        About
      </Link>
      <Link to="/" className="m-off" activeClassName="b">
        All Categories
      </Link>
      <ul className="m-off">
        {categories.map(category => (
          <CategoryLink key={category.id} category={category} />
        ))}
      </ul>
    </nav>
  );
}

export interface SidebarData {
  categories: {
    nodes: CategoryNode[];
  };
}

export const sidebarFragment = graphql`
  fragment SidebarFragment on Query {
    categories: allAirtable(filter: {table: {eq: "Categories"}, data: {name: {ne: null}}}) {
      nodes {
        id
        data {
          name
          parent {
            id
          }
        }
      }
    }
  }
`;

export default React.memo(Sidebar);
